import React, {useEffect} from 'react';

const CircleTest = () => {
    const Tmapv3 = window.Tmapv3;
    let map;
    let isMapLoaded =  false;
    useEffect(() => {
        if (Tmapv3) {
            if (!isMapLoaded) {
                const mapDiv = document.getElementById('map_div');
                mapDiv.innerHTML = '';
                map = new Tmapv3.Map("map_div", {
                    center : new Tmapv3.LatLng(37.56520450, 126.98702028),
                    width : "100%",	// 지도의 넓이
                    height : "400px",	// 지도의 높이
                    zoom : 16,	// 지도 줌레벨
                    naviControl: true,
                    scaleBar: true
                });
            }
            isMapLoaded = true;
        } else {
            console.error("Tmapv3 library not loaded.");
        }
    }, []);


    let redrawCircle = false;
    let toggleColor = false;
    let circle;
    const drawCircle = () => {
        circle = new Tmapv3.Circle({
            center: new Tmapv3.LatLng(37.56520450, 126.98372028),	// 중심좌표
            radius: 100,	// 원의 반지름. 크기설정
            strokeColor: "black",	// 테두리 색상
            fillColor: "white",	// 원 내부 색상
            map: map	// 지도 객체
        });
        console.log(circle.setColorOptions)
    }

    const changeColor = () => {
        console.log(circle.setColorOptions)
        if (toggleColor) {
            circle.setColorOptions({'fillColor': "red"});
        } else {
            circle.setColorOptions({'fillColor': "yellow"});
        }
        toggleColor = !toggleColor;
    }
    useEffect(() => {
        // if (mapOpened && gpsData.length > 0) {
        //     circles.current.forEach((circle) => {
        //         circle.setMap(null)
        //     })
        //     circles.current = []
        //
        //     gpsData.forEach((place) => {
        //         const { placeName, location, isInside, radius, distance } = place
        //         const { lat, lng } = location
        //
        //         const circle = new Tmapv3.Circle({
        //             center: new Tmapv3.LatLng(lat, lng),
        //             map: map.current,
        //             radius: radius,
        //             strokeOpacity: 0,
        //             fillOpacity: 0.5,
        //             fillColor: isInside ? '#F7FF00' : '#00ff6d',
        //         })
        //
        //         circles.current.push(circle)
        //     })
        // }
    }, [redrawCircle])
//


    return (
        <div>
            <div id="map_div"></div>
            <br/>
            <button onClick={drawCircle}>drawCircle</button>
            <button onClick={changeColor}>changeColor</button>
        </div>
    );
};

export default CircleTest;
